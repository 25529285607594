import React from 'react';

const Spinner = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center">
      <div
        style={{ borderTopColor: ' #e3661f' }}
        className="border-solid animate-spin rounded-full border-green-eveningSea border-4 h-10 w-10"
      />
    </div>
  );
};

export default Spinner;
