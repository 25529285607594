/* eslint-disable sonarjs/cognitive-complexity */
import { isValid as isValidCNPJ } from '@fnando/cnpj';
import { isValid as isValidCPF, strip } from '@fnando/cpf';
import { ApolloError } from 'apollo-boost';
import { GraphQLError } from 'graphql';
import moment from 'moment-timezone';

import { UserData, CompanyData, BankData } from '../pages/signup';
import t from './translations';

/* eslint-disable functional/immutable-data */
export type Error = {
  readonly statusCode: string;
  readonly error: string;
};

const parseError = (error: ApolloError | undefined): ApolloError | undefined => {
  if (!error) {
    return undefined;
  }

  const { graphQLErrors, ...apolloError } = error;

  // eslint-disable-next-line functional/prefer-readonly-type
  const newGraphQLErrors: GraphQLError[] = [];
  graphQLErrors?.map((graphQLError) => {
    const { message, ...newGraphqlError } = graphQLError;
    if (typeof graphQLError.message === 'string') {
      return newGraphQLErrors.push({
        message: message.length >= 255 ? t.error.ERROR : message,
        ...newGraphqlError,
      });
    }

    if (typeof graphQLError.message === 'object') {
      const nestError = message as any;

      if (typeof nestError.message === 'string') {
        return newGraphQLErrors.push({
          message: nestError.message,
          ...newGraphqlError,
        });
      }

      if (typeof nestError.message === 'undefined' && typeof nestError.error === 'string') {
        return newGraphQLErrors.push({
          message: nestError.error,
          ...newGraphqlError,
        });
      }

      return nestError?.message?.map((nestErrorMessage: any) => {
        Object.keys(nestErrorMessage.constraints).map((key) => {
          newGraphQLErrors.push({
            message: nestErrorMessage.constraints[key],
            ...newGraphqlError,
          });
        });
      });
    }
  });

  return {
    graphQLErrors: newGraphQLErrors,
    ...apolloError,
  };
};

const handleError = (error: any): readonly Error[] => {
  return error?.graphQLErrors?.map((gqError: any) => ({
    statusCode: gqError.message.statusCode,
    error: gqError.message,
  }));
};

const COUNTRY_CODE = '+55';
const DDD = '11';
const PHONE_NUMBER_LENGTH = 9;
const autoCompleteDDDPhone = (phone: string): string => {
  const number = strip(phone);
  if (number.length === PHONE_NUMBER_LENGTH) {
    return `${COUNTRY_CODE}${DDD}${number}`;
  }
  return `${COUNTRY_CODE}${number}`;
};
const masks = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};

const isArr = (arr: any): boolean => arr !== null && Array.isArray(arr) === true;
const isObj = (obj: any): boolean =>
  obj !== null && typeof obj === 'object' && Array.isArray(obj) === false;
const isStr = (str: string): boolean => str !== null && typeof str === 'string';
const isEmpty = (attr: any): boolean => {
  if (isStr(attr)) {
    return attr.length === 0;
  } else if (isArr(attr) || isStr(attr)) {
    return attr.length === 0;
  } else if (isObj(attr)) {
    return Object.keys(attr).length === 0;
  } else {
    return false;
  }
};

const groupByMonth = (items: readonly any[], key: string, monthFormat: string): object =>
  items.reduce((result: any, item: any) => {
    const month = moment(item[key]).format(monthFormat);
    return {
      ...result,
      [month]: [...(result[month] || []), item],
    };
  }, {});

const regex = {
  email: new RegExp('[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+'),
};

type FrontErrorData = {
  readonly administrator: {
    readonly message: string;
  };
  readonly email: {
    readonly message: string;
  };
  readonly phone: {
    readonly message: string;
  };
  readonly document: {
    readonly document: string;
  };
  readonly street: {
    readonly message: string;
  };
  readonly streetNumber: {
    readonly streetNumber: string;
  };
  readonly city: {
    readonly message: string;
  };
  readonly state: {
    readonly message: string;
  };
  readonly zipCode: {
    readonly message: string;
  };
  readonly fantasyName: {
    readonly message: string;
  };
  readonly companyName: {
    readonly message: string;
  };
  readonly bankName: {
    readonly message: string;
  };
  readonly bankAgency: {
    readonly message: string;
  };
  readonly bankAccount: {
    readonly message: string;
  };
};

const formValidator = (
  userData: UserData,
  companyData: CompanyData,
  bankData: BankData
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FrontErrorData => {
  const error: any = {};

  if (userData.administrator.length < 1) {
    error['administrator'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.administrator.length === 1 && userData.administrator.length <= 2) {
    error['administrator'] = { message: `${t.error.LENGTH_FIELD_ADMINISTRATOR}` };
  }
  if (!regex.email.test(userData.email)) {
    error['email'] = { message: `${t.error.INVALID_EMAIL}` };
  }
  if (userData.email.length < 1) {
    error['email'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.email.length < 2) {
    error['email'] = { message: `${t.error.INVALID_EMAIL}` };
  }

  if (userData.phone.length < 1) {
    error['phone'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.phone.length === 1 && userData.phone.length <= 2) {
    error['phone'] = { message: `${t.error.INVALID_PHONE}` };
  }
  if (userData.document.length < 1) {
    error['document'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.document) {
    const cpf = isValidCPF(strip(userData.document));
    const cnpj = isValidCNPJ(strip(userData.document));
    if (!cnpj && !cpf) {
      error['document'] = { message: `${t.error.INVALID_DOCUMENT}` };
    }
  }
  if (userData.street.length < 1) {
    error['street'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.street.length === 1 && userData.street.length <= 2) {
    error['street'] = { message: `${t.error.LENGTH_FIELD_STREET}` };
  }
  if (userData.streetNumber.length < 1) {
    error['streetNumber'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.streetNumber.length === 1 && userData.streetNumber.length <= 2) {
    error['streetNumber'] = { message: `${t.error.LENGTH_FIELD_STREET_NUMBER}` };
  }
  if (userData.city.length < 1) {
    error['city'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.city.length === 1 && userData.city.length <= 2) {
    error['city'] = { message: `${t.error.LEGTH_FIELD_CITY}` };
  }
  if (userData.state.length < 1) {
    error['state'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.state.length === 1 && userData.state.length <= 2) {
    error['state'] = { message: `${t.error.LENGTH_FIELD_STATE}` };
  }
  if (userData.zipCode.length < 1) {
    error['zipCode'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (userData.zipCode.length === 1 && userData.zipCode.length <= 2) {
    error['zipCode'] = { message: `${t.error.LENGTH_FIELD_ZIPCODE}` };
  }
  if (companyData.companyName.length < 1) {
    error['companyName'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (companyData.companyName.length === 1 && companyData.companyName.length <= 2) {
    error['companyName'] = { message: `${t.error.LENGTH_FIELD_COMPANY_NAME}` };
  }

  if (companyData.fantasyName.length < 1) {
    error['fantasyName'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (companyData.fantasyName.length === 1 && companyData.fantasyName.length <= 2) {
    error['fantasyName'] = { message: `${t.error.LENGTH_FIELD_NAME}` };
  }
  if (bankData.bankName.length < 1) {
    error['bankName'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (bankData.bankName.length === 1 && bankData.bankName.length <= 2) {
    error['bankName'] = { message: `${t.error.LENGTH_FIELD_BANK_}` };
  }

  if (bankData.bankAgency.length < 1) {
    error['bankAgency'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (bankData.bankAgency.length === 1 && bankData.bankAgency.length <= 2) {
    error['bankAgency'] = { message: `${t.error.LENGTH_FIELD_AGENCY}` };
  }
  if (bankData.bankAccount.length < 1) {
    error['bankAccount'] = { message: `${t.error.REQUIRED_FIELD}` };
  }
  if (bankData.bankAccount.length === 1 && bankData.bankAccount.length <= 2) {
    error['bankAccount'] = { message: `${t.error.LENGTH_FIELD_ACCOUNT}` };
  }
  if (companyData.categoryId.length <= 1) {
    error['categoryId'] = { message: `${t.error.REQUIRED_FIELD}` };
  }

  return error;
};

export async function safePromise<T>(
  promise: Promise<T>
): Promise<readonly [T | null, Error | null]> {
  try {
    const value = await promise;
    return [value, null];
  } catch (error) {
    return [null, error];
  }
}

export default {
  Error,
  handleError,
  masks,
  groupByMonth,
  isEmpty,
  parseError,
  autoCompleteDDDPhone,
  regex,
  formValidator,
  safePromise,
};
