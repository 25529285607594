import React from 'react';

const getVariant = (variant: string): string => {
  switch (variant) {
    case 'warning':
      return 'bg-orange-200 text-yellow-900';
    case 'success':
      return 'bg-teal-200 text-teal-900';
    case 'danger':
      return 'bg-red-200 text-red-800 ';
    default:
      return '';
  }
};

const Alert = ({
  children,
  testId,
  variant,
  className,
}: {
  readonly children: string;
  readonly testId: string;
  readonly variant: 'warning' | 'success' | 'danger';
  readonly className?: string;
}): JSX.Element => {
  const variantClassName = getVariant(variant);

  return (
    <div
      className={`${variantClassName} ${className} text-base rounded-sm py-4 px-3`}
      data-testid={testId}>
      {children}
    </div>
  );
};

export default Alert;
